import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Hero from '../components/Hero'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { JsonLd } from "react-schemaorg";
import { BreadcrumbList } from "schema-dts";

import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagram,
  FaEnvelope,
} from 'react-icons/fa';

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "map_hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth:1600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default ({data}) => (
  <>
   <JsonLd<BreadcrumbList>
      item={{
        "@context": "https://schema.org",
        "@type": "BreadcrumbList", 
      "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "PanSurg",
      "item": "http://www.pansurg.org"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "Contact",
      "item": "http://www.pansurg.org/contact"  
    }] }} />

<GatsbySeo title="Contact PanSurg"
      description="Wherever you are, whatever you're doing, we want to hear from you"/>
  <Hero title="Contact PanSurg"
        subtitle="Wherever you are, whatever you're doing, we want to hear from you"
        fluid={data.hero.childImageSharp.fluid}
        breadcrumbs
        activecrumb = 'Contact'
  />

  <section className="section is-small">
      <div className="content is-thin">
        <p>Please contact us using the form below, via <a href="mailto:admin@pansurg.org">email </a>
        or on <a href="https://twitter.com/pan_surg">Twitter</a>.</p>
      </div>
  </section>
  <hr className="is-divider"/>

    <section className="section is-small">
      <div className="container">
      <Formik
    initialValues={{
      name: '',
      email: '',
      institution:'',
      country:'',
      message: '',
    }}
    onSubmit={(values, actions) => {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...values })
      })
      .then(() => {
        alert('Message Sent! Thank you for your time and message, the PanSurg Team will get back to you as soon as possible.');
        actions.resetForm()
      })
      .catch(() => {
        alert('Error');
      })
      .finally(() => actions.setSubmitting(false))
    }}
    validate={values => {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      const errors = {};
      if(!values.name) {
        errors.name = 'Name Required'
      }
      if(!values.email || !emailRegex.test(values.email)) {
        errors.email = 'Valid Email Required'
      }
      if(!values.institution) {
        errors.institution = 'Institution Required'
      }
      if(!values.country) {
        errors.country = 'Country Required'
      }
      if(!values.message) {
        errors.message = 'Message Required'
      }
      return errors;
    }}
  >
  {() => (
        <Form name="contact" data-netlify={true}>
          <div className="field">
            <label className="label">Name*</label>
            <div className="control">
              <Field
                className="input"
                name="name"
                type="text"
                placeholder="Your name"
              />
            </div>
          </div>
          <ErrorMessage name="name" >{msg => <div className="has-text-red">{msg}</div>}</ErrorMessage>


          <div className="field">
            <label className="label">Email*</label>
            <div className="control">
              <Field
                className="input"
                name="email"
                type="email"
                placeholder="Your email address"
              />
            </div>
          </div>
          <ErrorMessage name="email" >{msg => <div className="has-text-red">{msg}</div>}</ErrorMessage>


          <div className="field">
            <label className="label">Institution*</label>
            <div className="control">
              <Field
                className="input"
                name="institution"
                type="institution"
                placeholder="Your institution"
              />
            </div>
          </div>
          <ErrorMessage name="institution" >{msg => <div className="has-text-red">{msg}</div>}</ErrorMessage>


          <div className="field">
            <label className="label">Country*</label>
            <div className="control">
              <Field
                className="input"
                name="country"
                type="country"
                placeholder="Your country"
              />
            </div>
          </div>
          <ErrorMessage name="country" >{msg => <div className="has-text-red">{msg}</div>}</ErrorMessage>


          <div className="field">
            <label className="label">Message*</label>
            <div className="control">
              <Field
                className="textarea"
                name="message"
                placeholder="Your message"
                component = "textarea"
              />
            </div>
          </div>
          <ErrorMessage name="message">{msg => <div className="has-text-red">{msg}</div>}</ErrorMessage>


          <div className="field is-grouped is-grouped-right">
            <div className="control">
              <button type="submit" className="button is-darkBlue is-rounded">
                Send message
              </button>
            </div>
          </div>
        </Form>
        )}
        </Formik>
      </div>
    </section>

    {/* <section className="section">
      <div className="container subtitle">
        <div className="is-thin">
          <a href="mailto:admin@pansurg.org">
            <FaEnvelope color="midBlue"/>
            &nbsp;
            admin@pansurg.org
          </a>
        </div>
      </div>
  </section> */}
  </>
)
